import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Snowflake from '../../containers/Services/Snowflake';

const snowflakeSections = [
  {
    title: 'Snowflake Deployment & Migration',
    summary:
      "Seamless migration from legacy systems to Snowflake, ensuring data integrity and minimizing downtime. Expert guidance on deploying Snowflake's Data Cloud to fit your specific business needs, optimizing for performance and cost.",
  },
  {
    title: 'Data Architecture Design',
    summary:
      "Crafting robust data architectures that leverage Snowflake's unique capabilities, ensuring scalability and flexibility for future growth. Implementing best practices in data modeling, warehouse design, and data lakes to maximize your Snowflake investment.",
  },
  {
    title: 'Data Integration & ETL',
    summary:
      "Streamlining data integration from various sources into Snowflake, utilizing ETL and ELT processes for efficient data transformation and loading. Leveraging Snowflake's native connectors and third-party tools to ensure a cohesive data ecosystem.",
  },
  {
    title: 'Data Governance & Security',
    summary:
      'Establishing comprehensive data governance frameworks within Snowflake, maintaining data quality and compliance. Implementing robust security measures, including role-based access control, encryption, and data masking, to protect sensitive information.',
  },
  {
    title: 'Analytics & Business Intelligence',
    summary:
      "Empowering organizations with real-time analytics and business intelligence capabilities by optimizing Snowflake's performance and tools. Custom BI solutions, dashboards, and reporting to unlock actionable insights, driving decision-making and competitive advantage.",
  },
  {
    title: 'Managed Services for Snowflake',
    summary:
      'Ongoing support and management of your Snowflake environment, including performance tuning, cost optimization, and scalability planning. Proactive monitoring and maintenance to ensure high availability and reliability of your data platform.',
  },
];

const whyChooseSnowflake = [
  {
    title: 'Certified Expertise',
    summary:
      'Our team of Snowflake-certified professionals brings a wealth of knowledge and hands-on experience, ensuring your projects are executed flawlessly.',
  },
  {
    title: 'Custom Solutions',
    summary:
      'We understand that one size does not fit all. Our approach is tailored to meet your unique business challenges and objectives.',
  },
  {
    title: 'Proven Track Record',
    summary:
      'With multiple successful Snowflake implementations under our belt, we are trusted by businesses across industries to deliver transformative data solutions.',
  },
  {
    title: 'Strategic Partnership',
    summary:
      'As a Snowflake partner, we have exclusive access to resources, training, and support, keeping us at the forefront of Snowflake innovations.',
  },
];

const title = 'Snowflake Solutions at Tech Holding';
const whyChooseTitle = 'Why Choose Tech Holding as Your Snowflake Partner?';

const contactUsContent = "Ready to leverage the full power of Snowflake's Data Cloud?";
const contactUsSummary =
  'Contact us today to discuss how we can help transform your data strategy and propel your business forward.';

const description =
  'Unlock the Full Potential of Your Data with Our Certified Snowflake Experts at Tech Holding, we are proud to be a strategic Snowflake partner, boasting a team of Snowflake-certified engineers, experienced data architects, and data experts dedicated to transforming your data into powerful insights. Our deep expertise in Snowflakes platform enables us to deliver customized, scalable, and secure data solutions that drive real business outcomes.';

const details = {
  title,
  whyChooseTitle,
  description,
  snowflakeSections,
  whyChooseSnowflake,
  contactUsContent,
  contactUsSummary,
};

const propTypes = {
  data: PropTypes.shape({
    contentfulCompanyQuote: PropTypes.shape({
      footerDescription: PropTypes.shape({}).isRequired,
    }),
  }).isRequired,
};

const SnowflakePage = ({ data }) => (
  <Snowflake details={details} companyQuote={data.contentfulCompanyQuote} />
);
SnowflakePage.propTypes = propTypes;

export default SnowflakePage;

export const query = graphql`
  query {
    contentfulCompanyQuote(label: { eq: "servicesAws" }) {
      footerDescription {
        json
      }
    }
  }
`;
