import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Button from '../../../components/Button';
import BadgeImage from '../../../assets/snowflakepage/badge-image.png';
import HeaderImage from '../../../assets/snowflakepage/header-image.png';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import s from './Snowflake.module.scss';

const propTypes = {
  details: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    whyChooseTitle: PropTypes.string.isRequired,
    contactUsContent: PropTypes.string.isRequired,
    snowflakeSections: PropTypes.array.isRequired,
    whyChooseSnowflake: PropTypes.array.isRequired,
    contactUsSummary: PropTypes.string.isRequired,
  }).isRequired,
  companyQuote: PropTypes.shape({
    footerDescription: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const seoParams = {
  ogImagePath: '/og-images/snowflake.png',
  ogTitle: 'Snowflake Solutions at Tech Holding',
  ogDesc:
    "Maximize your data's power with Tech Holding's certified Snowflake experts. Tailored solutions for seamless deployment, integration, governance, and analytics.",
  ogPath: '/services/snowflake',
};

const updatedSeo = getSeoConfig(seoParams);

const Snowflake = ({ details, companyQuote }) => {
  const {
    title,
    whyChooseTitle,
    description,
    snowflakeSections,
    whyChooseSnowflake,
    contactUsContent,
    contactUsSummary,
  } = details;
  return (
    <Layout
      currentPage="/services/snowflake"
      seo={updatedSeo}
      title={updatedSeo.openGraph.title}
      footerDescription={companyQuote.footerDescription}
    >
      <div className={s.bgGray}>
        <div className={s.headerContainer} data-test="headerContainer">
          <div className={s.headerContent}>
            <div className={s.title}>
              <h2 data-test="title">{title}</h2>
            </div>
            <div className={s.image} data-test="snowflakePartnerNetworkLogo">
              <img className={s.emoji} src={HeaderImage} alt="Snowflake Partner Network" />
            </div>
          </div>
        </div>
      </div>
      <div className={s.pageContainer} data-test="pageContainer">
        <div className={s.snowflakeContent}>
          <div className={s.content}>
            <p>{description}</p>
          </div>
          <div className={s.snowflakeBadges}>
            <div data-test="image">
              <img className={s.emoji} src={BadgeImage} alt="SnowPro Core Certified" />
            </div>
          </div>
        </div>
        <div className={s.snowflakeSectionsContainer}>
          {snowflakeSections.map((snowflakeSection) => (
            <div
              className={s.snowflakeSections}
              key={snowflakeSection.title}
              data-test="snowflakeSections"
            >
              <div className={s.content}>
                <h3>{snowflakeSection.title}</h3>
                <p>{snowflakeSection.summary}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={s.whyChoose}>
          <h2>{whyChooseTitle}</h2>
          <div className={s.snowflakeSectionsContainer}>
            {whyChooseSnowflake.map((snowflakeSection) => (
              <div
                className={s.snowflakeSections}
                key={snowflakeSection.title}
                data-test="whyChooseSnowflake"
              >
                <div className={s.content}>
                  <h3>{snowflakeSection.title}</h3>
                  <p>{snowflakeSection.summary}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={s.contactUsContent}>
        <div className={s.contactUsText}>
          <h3>{contactUsContent}</h3>
          <p className={s.contactUsSummary}>{contactUsSummary}</p>
        </div>
        <div className={s.button}>
          <Link to={`/contact`}>
            <Button theme="grey">Contact Us</Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

Snowflake.propTypes = propTypes;
export default Snowflake;
